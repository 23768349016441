import proj1 from "../static/images/mauricioPort.jpg";
import proj2 from "../static/images/UI.jpg";
export const ProjectList = [
    {
        name: "Personal Portfolio Website",
        image: proj1,
        id: 1,
        description: "A personal portfolio website to showcase my projects and skills.",
        link: "#",
        tools: "React, Material-UI",
        skills: "React, Material-UI, HTML, CSS, JavaScript, Git, GitHub, CloudFlare"
    },
    {
        name: "Full Stack Chess Application",
        image: proj2,
        id: 2,
        description: "A full stack chess application that allows users to play chess against each other. Utilized REST API to communicate between the front end and back end. Implemented a database to store user information and game history. Implemented a chess engine to validate moves and determine checkmate.",
        link: "#",
        tools: "Java Spark, React",
        skills: "Technologies: IntelliJ IDEA, Linux, Docker, Git, GitHub, Maven, NPM, Webpack, JUnit, Jest, Postman, Zenhub, and Code Climate. The technology tools used include: Linux, Java, JavaScript, React, Reactstrap, React Leaflet, SQL, MariaDB, Java Spark, Concurrency, SLF4J, uLog, JSON, CSV, SVG, and KML"
    }
];
